import $ from 'jquery'

// options.append: append the content to the modal container instead of replacing it
//                 In an ideal world, we would not have modals that open on top of other modals. But that's not the case for some things right now.
//                 Moreover, we only allow modals-on-top-of-other-modals for 2-level. After which we start replacing the last modal.
//                 This works fine for most use-cases but for things like multi-level object picker where there can be multiple levels of nested modals, things break.
//                 In those cases, we want to allow appending the modal to the existing modal conainer instead of replacing the last modal with the new one.
export const showModal = (content, options = { append: false }) => {
  const useModernModals =
    $(content).find('.t-modal').addBack().filter('.t-modal').length > 0

  let container = $(
    useModernModals ? '#tw-modal-container-ajax' : '#modal-container-ajax'
  )
  if (container.find('.modal, .t-modal').hasClass('in')) {
    container = $(
      useModernModals ? '#tw-modal-container-ajax-2' : '#modal-container-ajax-2'
    )
  }

  const existingModals = container.find('.modal, .t-modal')

  const $el = options.append
    ? container.append(content)
    : container.html(content)

  container.changed()

  const newModal = $el
    .find('.modal, .t-modal')
    .toArray()
    .find((el) => !existingModals.is(el))

  if (useModernModals) {
    // Defer so stimulus can initialize/connect
    setTimeout(() => newModal.modal('show'), 0)
    return newModal
  }

  // Legacy modals - bootstrap/jquery - only on admin
  return $(newModal).modal({ backdrop: 'static' })
}

// CALVIN: I really dislike this. We should not have modals that open on top of other modals.
// Whenever this happens, we need to rethink the UI.
// If you spot such a case, let me know about it so we can come up with a better solution
$(document).on('show.bs.modal', '.modal', function () {
  // Heavily from https://stackoverflow.com/questions/19305821/multiple-modals-overlay
  // One addition is the paddingTop
  const visibleModals = $('.modal:visible').length
  let zIndexOfVisibleModal = 0
  $('.modal:visible').map((i, elm) => {
    const elmZIndex = parseInt($(elm).css('z-index') || 0)
    if (elmZIndex > zIndexOfVisibleModal) {
      zIndexOfVisibleModal = elmZIndex
    }
  })
  const visibleDropdowns = $('.js-dropdown-content-container:visible').length
  const zIndex =
    1050 + (10 + zIndexOfVisibleModal) + (visibleDropdowns ? 9999 : 0)
  const paddingTop = Math.min(30 * visibleModals, 100)
  $(this).css('z-index', zIndex).css('padding-top', paddingTop)
  setTimeout(() => {
    $('.modal-backdrop')
      .not('.modal-stack')
      .css('z-index', zIndex - 1)
      .addClass('modal-stack')
    $('.modal-backdrop').first().addClass('base-modal-backdrop')
  })
})
$(document).on(
  'hidden.bs.modal',
  '.modal',
  () => $('.modal:visible').length && $(document.body).addClass('modal-open')
)
